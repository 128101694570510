import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Layout } from "../../../assets";

const Wrapper = styled.div<{ margin?: string }>`
  ${Layout.flexRowCenterLeft};
  gap: 0 12px;
  ${(p) => !!p.margin && `margin: ${p.margin}`};
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

interface Props {
  children: ReactNode;
  className?: string;
  margin?: string;
}

export const HorizontalScrollableBlock: FC<Props> = (props) => {
  const { children, ...rest } = props;
  return <Wrapper {...rest}>{children}</Wrapper>;
};
