import { FC } from "react";
import styled from "styled-components";

import { Icon } from "../Icon";

const CrossWrapper = styled.div<{ rightPosition?: string }>`
  height: 25px;
  width: 25px;
  background-color: ${(p) => p.theme.grayScale2_05};
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  right: ${(p) => p.rightPosition || 0};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  &:hover {
    background-color: ${(p) => p.theme.grayScale2_10};
  }
`;
const CrossIcon = styled(Icon)`
  fill: ${(p) => p.theme.grayScale2_70};
  transform: scale(1.2);
`;

export interface CrossIconProps {
  onClick: (e?: unknown) => void;
  rightPosition?: string;
  className?: string;
}
export const CrossIconComponent: FC<CrossIconProps> = (props) => {
  const { onClick, rightPosition, className } = props;
  return (
    <CrossWrapper
      onClick={onClick}
      rightPosition={rightPosition}
      className={className}
    >
      <CrossIcon name={"plus"} size={25} />
    </CrossWrapper>
  );
};
