import React, {FC, HTMLAttributes, InputHTMLAttributes} from "react";
import styled from "styled-components";

const Container = styled.div<{ size?: number }>`
    display: flex;
    width: 100%;
    height: ${(p) => p.size}px;
`;
const InputComponent = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    padding: 10px;

    ::placeholder {
        color: #999;
    }
`;
const Textarea = styled.textarea`
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;

    ::placeholder {
        color: #999;
    }
`;

const Error = styled.p`
    color: red;
`;

interface Props extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    placeholder?: string;
    size?: number;
    textArea?: boolean;
    error?: {
        [key: string]: string
    }
}

export const Input: FC<Props> = (props) => {
    const {placeholder, size, textArea, error, ...res} = props;
    console.log(error);
    return (
        <>
            <Container size={size}>
                {textArea ? (
                    <Textarea {...res} placeholder={placeholder}/>
                ) : (
                    <InputComponent {...res} placeholder={placeholder}/>
                )}
            </Container>
            {error?.[res?.name as string] && <Error>{error[res?.name as string]}</Error>}
        </>
    );
};
