import React, { FC } from "react";
import styled from "styled-components";
import { AppText } from "../../atoms";
import { NavBar } from "../../molecules/NavBar";
import { Section1 } from "../../../assets/Layout/Section1";
import { Section2 } from "../../../assets/Layout/Section2";
import { Images } from "../../../assets/Images";
import { Section3 } from "../../../assets/Layout/Section3";
import { Section4 } from "../../../assets/Layout/Section4";
import { Section5 } from "../../../assets/Layout/Section5";
import { Header } from "../../molecules/Header";
import { Block } from "../../atoms/Block";
import { Wrapper } from "../../atoms/Wrapper";

const Container = styled.div`
  width: 100vw;
  background: rgb(26 26 23);
`;

const ImagesMap = styled.div`
  width: 100%;
  height: 100vh;
  padding: 50px 0 250px 0;
  background-image: url(${Images.map});
  background-color: rgb(13 13 13);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  @media (max-width: 900px) {
    height: auto;
  }
`;
const ContentWrapper = styled(Wrapper)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px 5px;
  @media (max-width: 900px) {
    padding-bottom: 50px;
    align-items: center;
    flex-direction: column;
    width: min(600px, 100%);
  }
`;

const LeftBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  flex: 1;
  max-width: 470px;
  @media (max-width: 900px) {
    max-width: initial;
  }
`;

const RightBlock = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  & > div {
    @media (max-width: 900px) {
      width: 100%;
    }
    @media (max-width: 568px) {
      padding: 20px;
    }
  }
`;
const WelcomeTextWrapper = styled.div`
  & > p {
    font-family: tarunowide;
  }
`;
const Buttons = [
  {
    id: 1,
    name: "Why Choose PawnsBoard",
    sectionName: "section1",
    withOffset: false,
  },
  {
    id: 2,
    name: "PawnsBoard Story",
    sectionName: "section2",
    withOffset: true,
  },
  { id: 3, name: "Why Las Vegas", sectionName: "section3", withOffset: true },
  {
    id: 4,
    name: "Become a Venture",
    sectionName: "section4",
    withOffset: true,
  },
];
export const AppLayout: FC = () => {
  return (
    <Container>
      <Header RightComponent={<NavBar data={Buttons}></NavBar>} />
      <ImagesMap id={"section1"}>
        <ContentWrapper>
          <LeftBlock>
            <WelcomeTextWrapper>
              <AppText color={"#FFFFFF"} variant={"Regular32"}>
                Welcome to PawnsBoard:
              </AppText>
              <AppText margin={"8px 0"} color={"#FFFFFF"} variant={"regular20"}>
                Your Ultimate Marketplace for Pawn Shops
              </AppText>
            </WelcomeTextWrapper>
            <div>
              <AppText color={"#FFFFFF"} variant={"regular32"}>
                Why Choose PawnsBoard?
              </AppText>
              <AppText margin={"8px 0"} color={"#FFFFFF"} variant={"regular14"}>
                At PawnsBoard, we understand the unique needs of pawn shops and
                aim to provide tailored solutions that cater to your business
                requirements. Here's why you should join our growing community
                of pawnbrokers:
              </AppText>
            </div>
          </LeftBlock>
          <RightBlock>
            <Block
              color={"#194C49"}
              titleName={
                <AppText variant={"regular24"}>
                  Coming Soon to Las Vegas,NV, USA, and Expanding Nationwide
                </AppText>
              }
              description={
                <AppText variant={"regular14"}>
                  Are you ready to revolutionize the way pawn shops connect and
                  sell their items? Look no further! Introducing PawnsBoard, the
                  innovative online marketplace designed exclusively for pawn
                  shops across the United States. Our mission is to bring
                  together pawn shops, streamline their sales process, and
                  provide a platform to reach a wider audience of eager buyers.
                  Get ready to maximize your inventory and boost your profits!
                </AppText>
              }
            ></Block>
          </RightBlock>
        </ContentWrapper>
      </ImagesMap>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </Container>
  );
};
