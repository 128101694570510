import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Icons } from "../../../assets";
import { AppText, Icon } from "../../atoms";
const Container = styled.div``;
const CategoriesWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 25px;

  @media (max-width: 1100px) {
    left: ${({ isOpen }) => (isOpen ? `${0}` : `${window.innerWidth + 200}px`)};
    height: 100vh;
    overflow: hidden;
    backdrop-filter: blur(6px);
    width: ${({ isOpen }) => (isOpen ? `${100}%` : `${0}`)};
    background-color: rgba(241, 241, 241, 0.58);
    gap: 15px;
    position: absolute;
    z-index: 4;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition:
      left 0.3s,
      width 0.3s ${(p) => (p.isOpen ? 0 : 0.4)}s;
  }
`;

const CloseIcon = styled.div<{ topPosition: number }>`
  display: none;
  @media (max-width: 1100px) {
    cursor: pointer;
    display: block;
    position: absolute;
    top: ${(p) => p.topPosition}px;
    right: 30px;
    height: 30px;
    width: 30px;
    &:before,
    &:after {
      content: "";
      display: block;
      height: 3px;
      width: 100%;
      background: black;
      border-radius: 2px;
      position: absolute;
      top: 50%;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    @media (max-width: 568px) {
      top: 22px;
      right: 15px;
    }
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  @media (max-width: 1100px) {
    padding: 10px;
  }
`;
const CheckListButton = styled.button`
  display: none;
  @media (max-width: 1100px) {
    all: unset;
    cursor: pointer;
  }
`;
const TextButton = styled(AppText)`
  @media (max-width: 1100px) {
    font-size: 25px;
    color: #2f2b2b;
  }
`;
const IconBurger = styled(Icon)``;
export interface NavBarProps {
  onClick?: () => void;
  type?: "primary" | "secondary";
  size?: "normal" | "small" | "big";
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
  margin?: string;
  padding?: string;
  leftIcon?: string | keyof typeof Icons;
  rightIcon?: string | keyof typeof Icons;
  iconSize?: number;
  btnType?: "submit" | "button" | "reset" | undefined;
  className?: string;
  data: {
    id: number;
    name: string;
    sectionName: string;
    withOffset: boolean;
  }[];
}
export const NavBar: FC<NavBarProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [burgerSelect, setBurgerSelect] = useState(false);
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (isMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
    };
  }, [isMenuOpen]);



  const scrollToSection = (name: string, withOffset: boolean) => {
    const section = document.getElementById(name);
    if (section) {
      const positionTop = section.offsetTop;
      const offset =
        window.scrollY < 1 ? (window.innerWidth > 568 ? 122 : 80) : 80;
      const position = withOffset ? positionTop - offset : 0;
      window.scrollTo({ behavior: "smooth", top: position });
    }
  };

  const {
    data,
  } = props;

  return (
    <Container>
      <CheckListButton
        onClick={() => {
          setBurgerSelect(!burgerSelect);
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <IconBurger name={"burgerCheckList"} />
      </CheckListButton>
      <CategoriesWrapper isOpen={isMenuOpen}>
        {data.map((item, index) => (
          <Button
            key={index}
            onClick={() => {
              setBurgerSelect(false);
              setIsMenuOpen(false);
              scrollToSection(item.sectionName, item.withOffset);
            }}
          >
            <TextButton variant={"regular16"} color={"#000000"}>
              {item.name}
            </TextButton>
          </Button>
        ))}
        <CloseIcon
          topPosition={window.scrollY >= 300 ? 22 : 42}
          onClick={() => {
            setBurgerSelect(false);
            setIsMenuOpen(false);
          }}
        />
      </CategoriesWrapper>
    </Container>
  );
};
