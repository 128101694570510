import styled from "styled-components";

const Wrapper = styled.button`
  position: relative;
  height: 30px;
  width: 30px;
  min-width: 30px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.grayScale2_10};
  transition: 0.25s ease-in-out;
  &:hover {
    background-color: ${(p) => p.theme.primary1};
    & > div,
    & > div:before,
    & > div:after {
      background-color: ${(p) => p.theme.static1};
    }
  }
`;

const Items = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: ${(p) => p.theme.grayScale2_80};
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    position: absolute;
    content: "";
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.grayScale2_80};
  }
  &:before {
    transform: translateX(-7px);
  }
  &:after {
    transform: translateX(7px);
  }
`;

export const OptionIcon = () => {
  return (
    <Wrapper>
      <Items />
    </Wrapper>
  );
};
