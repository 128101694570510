import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import { ButtonProps } from "../Button";
import { Layout } from "../../../assets";

const SmallBtn = css`
  height: 30px;
  font-size: 12px;
  padding: 0 14px;
`;
const NormalBtn = css`
  height: 40px;
  font-size: 14px;
  padding: 0 14px;
`;
const BigBtn = css`
  height: 50px;
  font-size: 16px;
  padding: 0 20px;
`;
const BtnStyles = css<{
  size: FlattenSimpleInterpolation;
  disabled?: ButtonProps["disabled"];
  margin?: string;
  padding?: string;
}>`
  position: relative;
  ${Layout.flexRowCenter};
  outline: none;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "default")};
  border-radius: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  min-width: 140px;
  user-select: none;
  ${({ size }) => size};
  ${({ margin }) => !!margin && `margin: ${margin}`};
  ${({ padding }) => !!padding && `padding: ${padding}`};
  transition: all 0.3s ease-in-out;
`;

const PrimaryBtn = styled.button<{
  size: FlattenSimpleInterpolation;
  loading?: ButtonProps["loading"];
  disabled?: ButtonProps["disabled"];
}>`
  ${BtnStyles};
  border: none;
  background: ${({ disabled, loading, theme }) =>
    disabled && !loading ? theme.grayScale2_20 : theme.primary5};
  color: ${({ theme, disabled }) =>
    disabled ? theme.grayScale2_20 : theme.static1};
  ${({ loading }) => loading && `opacity: 0.8`};
  &:hover {
    ${({ disabled, theme }) => !disabled && `background: ${theme.primary6}`};
  }
  &:focus:before {
    content: "";
    ${Layout.fill};
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.static1},
      0 0 0 2px ${({ theme }) => theme.primary4};
    border-radius: 10px;
  }
`;

const SecondaryBtn = styled.button<{
  size: FlattenSimpleInterpolation;
  loading?: ButtonProps["loading"];
  disabled?: ButtonProps["disabled"];
}>`
  ${BtnStyles};
  border: solid 1px
    ${({ theme, disabled, loading }) =>
      disabled && !loading ? theme.grayScale2_20 : theme.grayScale2_80};
  background: transparent;
  color: ${({ theme, disabled, loading }) =>
    disabled && !loading ? theme.grayScale2_20 : theme.grayScale2_70};
  ${({ loading }) => loading && `opacity: 0.5`};
  &:hover {
    ${({ disabled, theme }) =>
      !disabled && `border: solid 1px ${theme.grayScale2_100}`};
    ${({ disabled, theme }) => !disabled && `color: ${theme.grayScale2_100}`};
    ${({ disabled, theme }) =>
      !disabled && `background: ${theme.grayScale2_10}`};
  }
  &:focus:before {
    content: "";
    ${Layout.fill};
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.grayScale2_100},
      0 0 0 2px ${({ theme }) => theme.static1},
      0 0 0 3px ${({ theme }) => theme.link};
    border-radius: 10px;
  }
`;

export { BigBtn, NormalBtn, PrimaryBtn, SecondaryBtn, SmallBtn };
