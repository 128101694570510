import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
const Container = styled.div<{
  width: number;
  bgColor?: string;
  padding: number;
}>`
  //max-width: 1760px;
  margin-inline: auto;
  width: min(${(p) => p.width}px, 100%);
  padding-inline: ${(p) => p.padding}px;
  position: relative;
  background-color: ${(p) => p.bgColor};
  @media (max-width: 568px) {
    padding-inline: ${(p) => p.padding / 2}px;
  }
`;
interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  widthContainer?: number;
  color?: string;
  padding?: number;
}
export const Wrapper: FC<SectionProps> = (props) => {
  const {
    children,
    widthContainer = 1640,
    color,
    padding = 30,
    ...rest
  } = props;
  return (
    <Container
      padding={padding}
      bgColor={color}
      width={widthContainer}
      {...rest}
    >
      {children}
    </Container>
  );
};
