import { FC, useMemo } from "react";

export interface LogoIconProps {
  height?: number | string;
  width?: number | string;
}
export const LogoIcon: FC<LogoIconProps> = (props) => {
  const { height, width } = props;
  const iconHeight = useMemo(() => (height ? `${height}` : "64"), [height]);
  const iconWidth = useMemo(() => (width ? `${width}` : "346"), [width]);
  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 346 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.5174 14.4574C58.6717 14.2016 62.826 13.9457 66.6608 13.9457C76.8868 13.9457 84.9398 15.545 84.9398 22.3259C84.9398 29.4266 75.9281 31.2818 64.8712 31.2818C63.1456 31.2818 61.356 31.2818 59.5665 31.1539V36.3355H54.5813V14.4574H54.5174ZM59.5026 26.9318C61.5478 26.9957 63.5291 27.0597 65.4464 27.0597C73.3716 27.0597 79.8907 26.1641 79.8907 22.3259C79.8907 18.8714 74.522 18.1038 67.6195 18.1038C65.063 18.1038 62.2508 18.2317 59.5026 18.3597V26.9318Z"
        fill="#194C49"
      />
      <path
        d="M95.2937 14.4575H105.392L116.96 36.3356H111.591L109.738 32.8172H91.0754L89.222 36.3356H83.8533L95.4215 14.4575H95.2937ZM107.437 28.6591L102.132 18.6156H98.4893L93.1846 28.6591H107.437Z"
        fill="#194C49"
      />
      <path
        d="M119.389 14.4575L125.46 32.1775H126.547L132.619 14.4575H140.735L146.807 32.1775H147.894L153.965 14.4575H159.078L151.601 36.3356H143.292L137.22 18.6156H136.262L130.19 36.3356H121.881L114.403 14.4575H119.516H119.389Z"
        fill="#194C49"
      />
      <path
        d="M161.954 14.4575H171.158L185.283 32.1775H186.625V14.4575H191.61V36.3356H182.726L168.537 18.6156H167.004V36.3356H162.018V14.4575H161.954Z"
        fill="#194C49"
      />
      <path
        d="M224.653 19.5112C222.416 18.7435 217.239 17.8479 211.039 17.8479C204.84 17.8479 200.302 19.2553 200.302 21.8141C200.302 23.0296 201.452 23.4134 203.114 23.4134H219.604C224.461 23.4134 227.209 25.3965 227.209 28.7869C227.209 33.8406 221.393 36.9112 210.145 36.9112C204.648 36.9112 198.832 36.1436 196.275 35.312V31.1539C198.512 31.9215 202.858 32.8171 210.145 32.8171C219.156 32.8171 222.288 31.2178 222.288 28.8509C222.288 27.8274 221.329 27.3156 219.476 27.3156H202.411C197.49 27.3156 195.317 25.2046 195.317 21.8781C195.317 16.6964 200.622 13.8177 211.167 13.8177C216.472 13.8177 222.032 14.5854 224.653 15.417V19.4472V19.5112Z"
        fill="#194C49"
      />
      <path
        d="M54.07 40.3656C58.7356 40.1737 63.3373 39.9178 67.3638 39.9178C76.3116 39.9178 82.8946 41.0693 82.8946 45.9951C82.8946 48.362 81.3607 49.8333 78.8042 50.665C82.2554 51.5606 84.3646 53.2238 84.3646 55.9106C84.3646 61.2841 76.5672 62.6275 66.2773 62.6275C62.4425 62.6275 58.2882 62.4356 54.07 62.1797V40.3017V40.3656ZM70.879 49.0017C74.9694 49.0017 77.5898 48.362 77.5898 46.5069C77.5898 44.6517 73.3077 44.1399 67.3638 44.1399C64.8073 44.1399 61.9951 44.2039 58.9913 44.3318V49.0017H70.8151H70.879ZM59.0552 58.3415C61.8034 58.4694 64.4238 58.5334 66.9164 58.5334C73.819 58.5334 79.0598 57.8297 79.0598 55.4628C79.0598 53.5437 76.3116 52.776 70.9429 52.776H59.1191V58.2775L59.0552 58.3415Z"
        stroke="#194C49"
      />
      <path
        d="M88.0076 51.3047C88.0076 43.1164 93.0567 39.598 104.753 39.598C116.449 39.598 121.498 43.1164 121.498 51.3047C121.498 59.493 116.449 63.0114 104.753 63.0114C93.0567 63.0114 88.0076 59.493 88.0076 51.3047ZM104.753 58.8533C113.189 58.8533 116.513 56.7422 116.513 51.3047C116.513 45.8672 113.189 43.7561 104.753 43.7561C96.3162 43.7561 92.9928 45.8672 92.9928 51.3047C92.9928 56.7422 96.3162 58.8533 104.753 58.8533Z"
        stroke="#194C49"
      />
      <path
        d="M134.728 40.3656H144.826L156.394 62.2437H151.025L149.172 58.7253H130.509L128.656 62.2437H123.287L134.855 40.3656H134.728ZM146.871 54.5671L141.566 44.5237H137.923L132.619 54.5671H146.871Z"
        stroke="#194C49"
      />
      <path
        d="M173.267 57.126C170.71 57.126 168.026 57.126 165.278 57.0621V62.2437H160.293V40.3656C164.447 40.1098 168.601 39.8539 172.436 39.8539C182.662 39.8539 190.715 41.4532 190.715 48.2341C190.715 51.9444 187.839 54.2473 183.621 55.015V55.3348C188.734 56.3584 190.907 59.1731 191.418 62.1797H186.177C185.538 59.429 182.279 57.126 173.203 57.0621L173.267 57.126ZM165.278 52.84C167.323 52.9039 169.304 52.9679 171.222 52.9679C179.147 52.9679 185.666 52.0723 185.666 48.2341C185.666 44.7796 180.297 44.012 173.395 44.012C170.838 44.012 168.026 44.1399 165.278 44.2679V52.84Z"
        stroke="#194C49"
      />
      <path
        d="M195.892 40.3657C199.727 40.0458 203.561 39.726 207.141 39.726C217.814 39.726 226.25 42.0929 226.25 51.3047C226.25 60.5165 217.75 62.8834 207.141 62.8834C203.561 62.8834 199.727 62.6275 195.892 62.2437V40.3657ZM200.877 58.3415C202.858 58.4055 204.84 58.5334 206.693 58.5334C214.682 58.5334 221.265 57.19 221.265 51.3047C221.265 45.4194 214.618 44.012 206.693 44.012C204.776 44.012 202.794 44.076 200.813 44.2039V58.3415H200.877Z"
        stroke="#194C49"
      />
      <path
        d="M232.194 56.998C233.984 56.998 235.134 58.1495 235.134 59.8128C235.134 61.476 233.92 62.6275 232.194 62.6275C230.469 62.6275 229.254 61.476 229.254 59.8128C229.254 58.1495 230.469 56.998 232.194 56.998Z"
        stroke="#194C49"
      />
      <path
        d="M255.778 62.8834C247.022 62.8834 239.608 61.0922 239.608 51.3047C239.608 41.5171 247.022 39.726 255.778 39.726C264.534 39.726 268.624 41.1973 268.624 41.1973V45.3554C268.624 45.3554 263.128 43.8201 255.842 43.8201C249.962 43.8201 244.593 44.012 244.593 51.2407C244.593 57.9577 249.131 58.5974 255.906 58.5974C263.384 58.5974 268.624 57.126 268.624 57.126V61.2841C268.624 61.2841 263.959 62.7555 255.778 62.7555V62.8834Z"
        stroke="#194C49"
      />
      <path
        d="M272.395 51.3047C272.395 43.1164 277.444 39.598 289.14 39.598C300.836 39.598 305.885 43.1164 305.885 51.3047C305.885 59.493 300.836 63.0114 289.14 63.0114C277.444 63.0114 272.395 59.493 272.395 51.3047ZM289.14 58.8533C297.577 58.8533 300.9 56.7422 300.9 51.3047C300.9 45.8672 297.577 43.7561 289.14 43.7561C280.704 43.7561 277.38 45.8672 277.38 51.3047C277.38 56.7422 280.704 58.8533 289.14 58.8533Z"
        stroke="#194C49"
      />
      <path
        d="M310.168 40.3656H320.138L327.104 58.0855H328.063L335.03 40.3656H345V62.2437H340.015V44.5237H338.225L331.259 62.2437H323.845L316.942 44.5237H315.153V62.2437H310.168V40.3656Z"
        stroke="#194C49"
      />
      <path
        d="M28.2493 0.639709C25.8846 0.191913 23.8394 0 22.1137 0C15.7225 0 13.1021 3.26252 11.5042 12.2824C9.45904 23.7972 12.0795 27.0597 23.1363 29.0428C25.5011 29.4906 27.5463 29.6825 29.2719 29.6825C35.6632 29.6825 38.2836 26.42 39.8814 17.4001C41.9266 5.88532 39.3062 2.62281 28.2493 0.639709Z"
        fill="#194C49"
      />
      <path
        d="M27.2907 31.4097C25.9485 31.2818 24.5424 31.1539 22.8168 30.834C20.5798 30.4502 18.8542 29.9384 17.2564 29.4266C15.4029 31.9215 13.5495 34.6083 11.8238 37.423C1.98129 53.5437 0 62.8195 0 62.8195H30.2946C30.2946 62.8195 27.6741 54.5032 27.0989 39.534C26.9711 36.6553 27.0989 33.9686 27.2907 31.4097Z"
        fill="#194C49"
      />
    </svg>
  );
};
