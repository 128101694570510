import styled, { keyframes } from "styled-components";
import { Icon } from "../../Icon";
import { Layout } from "../../../../assets";
const rotate360 = keyframes`
  from {
    transform:  rotate(0deg);
  }
  to {
    transform:  rotate(360deg);
  }
`;

const Spinner = styled.div<{
  size: "normal" | "small" | "big";
  type: "primary" | "secondary";
  loading?: boolean;
}>`
  animation: ${rotate360} 1s linear infinite;
  border-top: ${(p) =>
    ` ${!p.loading ? 0 : 2}px solid
      ${p.type === "primary" ? "#f3f4f6" : p.theme.grayScale2_60}}`};
  ${(p) =>
    `border-right: ${!p.loading ? 0 : 2}px solid
      ${p.type === "primary" ? "#f3f4f6" : p.theme.grayScale2_60}}`};
  ${(p) =>
    `border-bottom: ${!p.loading ? 0 : 2}px solid
      ${p.type === "primary" ? p.theme.static1 : p.theme.grayScale2_90}}`};
  ${(p) =>
    `border-left: ${!p.loading ? 0 : 4}px solid
      ${p.type === "primary" ? "#f3f4f6" : p.theme.grayScale2_60}}`};
  background: transparent;
  width: ${(p) =>
    p.loading
      ? p.size === "small"
        ? "10px"
        : p.size === "big"
        ? "16px"
        : "13px"
      : "0px"};
  height: ${(p) =>
    p.loading
      ? p.size === "small"
        ? "10px"
        : p.size === "big"
        ? "16px"
        : "13px"
      : "0px"};
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
`;

const BtnIconWrapper = styled.div<{ size: number }>`
  margin-right: 5px;
  height: ${({ size }) => size || 30}px;
  width: ${({ size }) => size || 30}px;
  ${Layout.flexRowCenter};
`;

const StyledIcon = styled(Icon)<{
  type: "primary" | "secondary";
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
  size: number;
}>`
  height: ${(p) => (p.loading || p.success ? "0" : `${p.size}px`)};
  width: ${(p) => (p.loading || p.success ? "0" : `${p.size}px`)};
  fill: ${(p) =>
    p.type === "primary"
      ? p.theme.static1
      : p.disabled
      ? p.theme.grayScale2_20
      : p.theme.grayScale2_70};
  transition: all 0.2s ease-in-out;
`;

const TextWrapper = styled.div<{
  loading?: boolean;
  success?: boolean;
  icon: boolean;
}>`
  transform: translateX(
    ${(p) => (!p.icon ? (p.loading || p.success ? "0" : "-15px") : "0")}
  );
  transition: transform 0.2s ease-in-out;
`;

export { BtnIconWrapper, Spinner, StyledIcon, TextWrapper };
