import React, {FC} from "react";
import styled from "styled-components";
import {Images} from "../../Images";
import {AppText} from "../../../Components/atoms";
import { Wrapper } from "../../../Components/atoms/Wrapper";
const Container=styled.div`
    width:100%;
  background: #FFB800;
`
const Image=styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 100vh;
`
const BottomBlock=styled.div`
    display: flex;
  justify-content: space-between;
  padding: 40px 50px;
  @media (max-width: 1150px) {
  display: flex;
  flex-direction: column;
    width: min(100%, 600px);
    margin-inline: auto;
    padding: 20px 0 30px;
}  
`
const LeftBlock=styled.div`
  flex:1;
`
const RightBlock=styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex:2;
`
export const Section3:FC=()=>{
    return (
        <Container id={"section3"}>
            <Image src={Images.lasVegas}/>
            <Wrapper widthContainer={1760} >
        <BottomBlock>
            <LeftBlock>
                <AppText margin={"0 0 10px 0"} color={"#0F1717"} variant={"regular32"}>Why Las Vegas</AppText>
            </LeftBlock>
            <RightBlock>
                <AppText variant={"bold20"}>Unveiling PawnsBoard: Why Las Vegas is the Perfect Launchpad for Our Revolutionary Marketplace</AppText>
                <AppText variant={"regular14"}>
                    In the bustling city of Las Vegas, where excitement and innovation collide, we have chosen to embark on our journey to transform the pawn industry. Las Vegas, known as the Entertainment Capital of the World, provides the ideal setting for us to introduce PawnsBoard, the ultimate marketplace for pawn shops. Here's why we have handpicked Las Vegas as the perfect starting point for our groundbreaking project:</AppText>
            </RightBlock>
        </BottomBlock>
            </Wrapper>
        </Container>
    )
}
