import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { LogoIcon } from "../../atoms";
import { Wrapper } from "../../atoms/Wrapper";

const EmptyBlock = styled.div<{ isSticky: boolean }>`
  height: ${(p) => (p.isSticky ? 80 : 122)}px;
  transition: 0.3s;
  @media (max-width: 568px) {
    height: 80px;
  }
`;

const Container = styled.div<{ isSticky: boolean }>`
  width: 100%;
  background: rgb(241, 241, 241);
  z-index: 100;
  transition: 0.3s;
  position: fixed;
  top: 0;
  height: ${(p) => (p.isSticky ? 80 : 122)}px;
  @media (max-width: 568px) {
    height: 80px;
  }
`;
const WrapperHeader = styled(Wrapper)`
  height: 100%;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LogoWrapper = styled.div<{ isSticky: boolean }>`
  width: 346px;
  height: 64px;
  cursor: pointer;
  @media (max-width: 568px) {
    width: 200px;
    height: 40px;
  }
  ${(p) =>
    p.isSticky &&
    `
    width: 200px;
    height: 40px;
  `}
`;
interface Props extends HTMLAttributes<HTMLDivElement> {
  LeftComponent?: ReactNode;
  RightComponent?: ReactNode;
}
export const Header: FC<Props> = (props) => {
  const {  LeftComponent, RightComponent,  } = props;
  const [isSticky, setIsSticky] = useState(window.scrollY >= 1);
  const checkHeaderIsSticky = useCallback(() => {
    if (window.scrollY > 0 && !isSticky) {
      setIsSticky(true);
    } else if (window.scrollY < 1) {
      setIsSticky(false);
    }
  }, [isSticky]);
  useEffect(() => {
    window.addEventListener("scroll", checkHeaderIsSticky, false);
    return () =>
      window.removeEventListener("scroll", checkHeaderIsSticky, false);
  });
  return (
    <>
      <Container isSticky={isSticky}>
        <WrapperHeader>
          {!LeftComponent ? (
            <LogoWrapper
              isSticky={isSticky}
              onClick={() => {
                window.scrollTo({
                  behavior: "smooth",
                  top: 0,
                });
              }}
            >
              <LogoIcon height={"100%"} width={"100%"} />
            </LogoWrapper>
          ) : (
            LeftComponent
          )}
          {RightComponent}
        </WrapperHeader>
      </Container>
      <EmptyBlock isSticky={isSticky} />
    </>
  );
};
