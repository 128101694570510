import styled, { css, FlattenSimpleInterpolation } from "styled-components";

const baseStyles = css<{
  type?: "p" | "span" | "li";
  fz?: number;
  align?: "left" | "right" | "center" | "justify";
  decoration?: "none" | "underline" | "line-through";
  fs?: "normal" | "italic";
  transform?: "none" | "uppercase" | "lowercase" | "capitalize";
  color?: string;
  margin?: string;
  padding?: string;
  variant?: FlattenSimpleInterpolation;
  isEllipsis?: boolean;
  isNotSelectable?: boolean;
  width?: string;
}>`
  ${({ variant }) => variant};
  ${({ fz }) => fz && ` font-size: ${fz}px`};
  color: ${({ color, theme }) => color || theme.grayScale2_70};
  font-style: ${({ fs }) => fs || "normal"};
  text-align: ${({ align }) => align || "left"};
  ${({ transform }) => !!transform && `text-transform: ${transform}`};
  ${({ decoration }) => !!decoration && `text-decoration: ${decoration}`};
  ${({ margin }) => !!margin && `margin: ${margin}`};
  ${({ padding }) => !!padding && `padding: ${padding}`};
  ${({ width }) => !!width && `width: ${width}`};
  ${({ isNotSelectable }) => !!isNotSelectable && "user-select: none"};
  ${(p) =>
    p.isEllipsis &&
    `
    display: -webkit-box;
    overflow: hidden;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  `}
`;

// ${({ isEllipsis }) => isEllipsis && 'overflow: hidden'};
// ${({ isEllipsis }) => isEllipsis && `white-space: nowrap`};
// ${({ isEllipsis }) => isEllipsis && 'text-overflow: ellipsis'};

const StyledSpan = styled.span`
  ${baseStyles};
`;

const StyledParagraph = styled.p`
  ${baseStyles};
`;

const StyledLi = styled.li`
  ${baseStyles};
`;

export { StyledLi, StyledParagraph, StyledSpan };
