import lasVegas from "./lasVegas.jpg";
import monitor from "./monitor.png";
import people from "./people.png";
import map from "./map.jpg";
export const Images = {
  lasVegas,
  monitor,
  people,
  map,
};
