import { ButtonHTMLAttributes, FC, ReactNode } from "react";

import { ButtonInside } from "./ButtonInside/ButtonInside";
import {
  BigBtn,
  NormalBtn,
  PrimaryBtn,
  SecondaryBtn,
  SmallBtn,
} from "./styles";
import { Icons } from "../../../assets/icons";
export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  onClick?: () => void;
  type?: "primary" | "secondary";
  size?: "normal" | "small" | "big";
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
  margin?: string;
  padding?: string;
  leftIcon?: string | keyof typeof Icons;
  rightIcon?: string | keyof typeof Icons;
  iconSize?: number;
  btnType?: "submit" | "button" | "reset" | undefined;
  className?: string;
  children: ReactNode;
}

export const Button: FC<ButtonProps> = (props) => {
  const {
    type = "primary",
    loading,
    success,
    disabled,
    size = "normal",
    leftIcon,
    rightIcon,
    iconSize = 30,
    btnType = undefined,
    children,
    ...rest
  } = props;

  const buttonSizes = {
    normal: NormalBtn,
    small: SmallBtn,
    big: BigBtn,
  };

  switch (type) {
    case "secondary":
      return (
        <SecondaryBtn
          size={buttonSizes[size]}
          loading={loading}
          disabled={disabled}
          type={btnType}
          {...rest}
        >
          <ButtonInside
            type={type}
            size={size}
            loading={loading}
            disabled={Boolean(disabled || loading)}
            success={success}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            iconSize={iconSize}
          >
            {children}
          </ButtonInside>
        </SecondaryBtn>
      );
    default:
      return (
        <PrimaryBtn
          size={buttonSizes[size]}
          loading={loading}
          disabled={disabled}
          type={btnType}
          {...rest}
        >
          <ButtonInside
            type={type}
            size={size}
            loading={loading}
            disabled={Boolean(disabled || loading)}
            success={success}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            iconSize={iconSize}
          >
            {children}
          </ButtonInside>
        </PrimaryBtn>
      );
  }
};
