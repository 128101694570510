import styled, { css } from "styled-components";

import { AppText } from "../AppText";
import { Layout } from "../../../assets";

const Wrapper = styled.div`
  ${Layout.flexRowCenterLeft};
  gap: 0 12px;
`;

const StyledLabel = styled.label`
  display: inline-block;
  height: 30px;
  position: relative;
  width: 68px;
  & > input {
    display: none;
  }
`;

const SwitchBlock = styled.div<{ isChecked: boolean; isNotAnimated: boolean }>`
  ${Layout.fill};
  background-color: ${({ theme, isChecked }) =>
    isChecked ? theme.success : theme.grayScale2_10};
  cursor: pointer;
  border-radius: 45px;
  ${({ isNotAnimated }) =>
    !isNotAnimated && "transition: all 0.4s ease-in-out"};
  &:before {
    content: "";
    background-color: ${({ theme, isChecked }) =>
      isChecked ? `rgba(255,255,255,0.9)` : theme.grayScale1_30};
    border-radius: 50%;
    height: 26px;
    width: 26px;
    left: 2px;
    top: 2px;
    position: absolute;
    ${({ isChecked }) => isChecked && "transform: translateX(38px)"};
    ${({ isNotAnimated }) =>
      !isNotAnimated && "transition: all 0.4s ease-in-out"};
  }
`;

const LabelsStyles = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  user-select: none;
`;

const LabelOn = styled.span<{ isChecked: boolean; isNotAnimated: boolean }>`
  ${LabelsStyles};
  left: 13.9px;
  color: ${({ theme }) => theme.static1};
  opacity: ${({ isChecked }) => (isChecked ? "1" : "0")};
  ${({ isNotAnimated }) =>
    !isNotAnimated && "transition: all 0.2s ease-in-out"};
`;

const LabelOff = styled.span<{ isChecked: boolean; isNotAnimated: boolean }>`
  ${LabelsStyles};
  right: 10.8px;
  color: ${({ theme }) => theme.grayScale2_20};
  opacity: ${({ isChecked }) => (isChecked ? "0" : "1")};
  transition: all ${({ isNotAnimated }) => (isNotAnimated ? 0 : 0.2)}s
    ${({ isChecked }) => (isChecked ? 0 : 0.2)}s ease-in-out;
`;

const Label = styled(AppText)`
  color: ${({ theme }) => theme.grayScale2_40};
`;

export { Label, LabelOff, LabelOn, StyledLabel, SwitchBlock, Wrapper };
