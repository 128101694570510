import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
const Container = styled.div<{ color?: string }>`
  width: clamp(370px, 40vw, 490px);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.color};
  color: white;
  border-radius: 20px;
  gap: 7px;
`;
interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  titleName?: ReactNode;
  description?: ReactNode;
  color?: string;
}
export const Block: FC<SectionProps> = (props) => {
  const { titleName, description, color } = props;
  return (
    <Container color={color}>
      {titleName}
      {description}
    </Container>
  );
};
