import { css } from "styled-components";

const regular10 = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
`;
const regular12 = css`
  font-size: clamp(10px, 1vw, 12px);
  color: blue;
  font-weight: 400;
`;
const regular14 = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
`;
const regular16 = css`
  font-weight: 700;
  line-height: 18.75px;
  font-size: clamp(14px, 1vw, 16px);
`;
const regular18 = css`
  font-size: clamp(12px, 1vw, 18px);
  font-weight: 400;
`;
const regular20 = css`
  font-size: clamp(14px, 1vw, 20px);
  font-weight: 400;
`;
const regular24 = css`
  font-weight: 400;
  font-size: clamp(16px, 3vw, 24px);
  line-height: clamp(19px, 3vw, 29px);
`;
const regular32 = css`
  font-weight: 900;
  line-height: 37.5px;
  font-size: clamp(27px, 3vw, 32px);
`;
const Regular32 = css`
  font-weight: 500;
  font-size: clamp(27px, 6vw, 32px);
  line-height: clamp(27px, 6vw, 41.6px);
`;
const medium10 = css`
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
`;
const medium12 = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;
const medium14 = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;
const medium16 = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;
const medium18 = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
`;
const medium20 = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
const medium24 = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`;
const semiBold10 = css`
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
`;
const semiBold12 = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;
const semiBold14 = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;
const semiBold16 = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;
const semiBold18 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;
const semiBold20 = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;
const semiBold24 = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;
const bold10 = css`
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
`;
const bold12 = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;
const bold14 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
const bold16 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;
const bold18 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;
const bold20 = css`
  font-weight: 900;
  //font-size: 20px;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 24px;
`;
const bold24 = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;
const big64 = css`
  font-weight: 400;
  //font-size: 64px;
  font-size: clamp(30px, 6vw, 64px);
  //line-height: 75px;
  line-height: clamp(35px, 6vw, 75px);
`;
const big90 = css`
  font-weight: 900;
  //font-size: 90px;
  font-size: clamp(30px, 6vw, 90px);
  line-height: clamp(40px, 6vw, 105px);
  //line-height: 105px;
`;
const big96 = css`
  font-weight: 900;
  //font-size:  96px;
  font-size: clamp(45px, 6vw, 96px);
  line-height: clamp(45px, 6vw, 112px);
`;
const big = css`
  font-weight: 900;
  font-size: clamp(70px, 10vw, 128px);
  line-height: clamp(70px, 10vw, 150px);
`;

export {
  bold10,
  bold12,
  bold14,
  bold16,
  bold18,
  bold20,
  bold24,
  medium10,
  medium12,
  medium14,
  medium16,
  medium18,
  medium20,
  medium24,
  regular10,
  regular12,
  regular14,
  regular16,
  regular18,
  regular20,
  regular24,
  regular32,
  Regular32,
  semiBold10,
  semiBold12,
  semiBold14,
  semiBold16,
  semiBold18,
  semiBold20,
  semiBold24,
  big64,
  big90,
  big96,
  big,
};
