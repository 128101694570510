import styled from "styled-components";

import { AppText } from "../AppText";
import { Layout } from "../../../assets";

const Wrapper = styled.div`
  display: flex;
`;

const RadioLabel = styled.label<{
  size: "normal" | "small";
  isHaveLabel: boolean;
  disabled: boolean;
}>`
  ${Layout.flexRowCenterLeft};
  position: relative;
  ${(p) =>
    !p.isHaveLabel &&
    `
      height: ${p.size === "normal" ? "24px" : "16px"};
      width: ${p.size === "normal" ? "24px" : "16px"};
  `}

  & > input {
    height: 0;
    width: 0;
    display: none;
    &:focus + div {
      box-shadow:
        0 0 0 1px ${(p) => p.theme.static1},
        0 0 0 2px ${(p) => p.theme.link};
    }
  }

  ${(p) =>
    p.disabled
      ? `
    opacity: 0.5;
    cursor: initial;
    pointer-events: none
  `
      : ` cursor: pointer;`}
`;

const RadioBlock = styled.div<{
  selected: boolean;
  size: "normal" | "small";
  isHaveLabel: boolean;
}>`
  ${Layout.fill};
  ${(p) =>
    p.isHaveLabel &&
    `
      height: ${p.size === "normal" ? "24px" : "16px"};
      width: ${p.size === "normal" ? "24px" : "16px"};
  `}

  background-color: ${(p) =>
    p.selected ? p.theme.success : p.theme.grayScale1_100};
  border: solid 1px
    ${(p) => (p.selected ? p.theme.success : p.theme.grayScale2_70)};
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  &:before {
    content: "";
    height: ${(p) =>
      !p.selected ? "0px" : p.size === "normal" ? "12px" : "8px"};
    width: ${(p) =>
      !p.selected ? "0px" : p.size === "normal" ? "12px" : "8px"};
    background-color: ${(p) => p.theme.static1};
    border-radius: 50%;
    ${Layout.absoluteCenter};
    transition: all 0.4s cubic-bezier(0.5, 2.5, 0.5, 1);
  }
`;

const Label = styled(AppText)<{ size: "normal" | "small" }>`
  padding: ${(p) =>
    p.size === "normal" ? "5px 10px 5px 35px" : "1px 10px 1px 27px"};
  user-select: none;
  line-height: unset;
`;

export { RadioBlock, RadioLabel, Wrapper, Label };
