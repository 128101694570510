import styled from "styled-components";
import { FC, HTMLAttributes, ReactElement } from "react";

const Wrapper = styled.div<{ selected: boolean; disabled: boolean }>`
  width: 100%;
  background-color: ${(p) => p.theme.bgCard};
  padding: 20px;
  border-radius: 8px;
  cursor: ${(p) => (p.disabled ? "unset" : "pointer")};
  box-shadow: ${(p) =>
    p.selected
      ? `0 0 0 2px ${p.theme.success}, 0 8px 16px rgba(0, 0, 0, 0.06)`
      : "0 8px 16px rgba(0, 0, 0, 0.06)"};
  ${(p) =>
    p.disabled &&
    `
    opacity: 0.3; pointer-events: none
  `};
  transition: 0.25s ease-in-out;
`;

export interface CardWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactElement;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
}
export const CardWrapper: FC<CardWrapperProps> = (props) => {
  const {
    children,
    disabled = false,
    selected = false,
    className,
    ...rest
  } = props;
  return (
    <Wrapper
      className={className}
      selected={selected}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};
