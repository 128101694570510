import { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "../../Icon";
import { BtnIconWrapper, Spinner, StyledIcon, TextWrapper } from "./styles";

const CheckedIcon = styled(Icon)<{
  success: boolean;
  type: "primary" | "secondary";
  size?: number;
}>`
  fill: ${({ theme, type }) =>
    type === "primary" ? theme.static1 : theme.grayScale2_70};
  height: ${({ success, size }) => (success && size ? `${size}px` : 0)};
  width: ${({ success, size }) => (success && size ? `${size}px` : 0)};
  transition: all 0.3s ease-in-out;
`;

export interface ButtonInsideProps {
  loading?: boolean;
  success?: boolean;
  disabled?: boolean;
  iconSize: number;
  leftIcon?: string;
  rightIcon?: string;
  type: "primary" | "secondary";
  size: "normal" | "small" | "big";
  children: ReactNode;
}
export const ButtonInside: FC<ButtonInsideProps> = (props) => {
  const {
    loading,
    success,
    iconSize,
    leftIcon,
    rightIcon,
    type,
    size,
    disabled,
    children,
  } = props;
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (success) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 800);
    }
  }, [success]);

  return (
    <>
      <BtnIconWrapper size={iconSize}>
        {!!leftIcon && (
          <StyledIcon
            type={type}
            disabled={disabled}
            loading={loading}
            success={isSuccess}
            name={leftIcon}
            size={iconSize}
          />
        )}
        <Spinner size={size} loading={loading} type={type} />
        <CheckedIcon
          size={iconSize}
          type={type}
          name={"logo"}
          success={isSuccess}
        />
      </BtnIconWrapper>
      <TextWrapper
        icon={Boolean(leftIcon)}
        loading={loading}
        success={isSuccess}
      >
        {children}
      </TextWrapper>
      {/*<BtnIconWrapper size={iconSize}>*/}
      {!!rightIcon && (
        <StyledIcon
          type={type}
          disabled={disabled}
          loading={loading}
          success={isSuccess}
          name={rightIcon}
          size={iconSize}
        />
      )}
    </>
  );
};
