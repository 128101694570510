import styled, {
  css,
  FlattenInterpolation,
  keyframes,
  ThemeProps,
} from "styled-components";
import { Layout } from "../../../assets";

const Wrapper = styled.div`
  ${Layout.flexColCenter};
  gap: 10px 0;
`;
const rotate360 = keyframes`
  from {
    transform:  rotate(0deg);
  }
  to {
    transform:  rotate(360deg);
  }
`;

const SmallStyles = css`
  ${({ theme }) => `border-top: 2px solid ${theme.primary5}`};
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  height: 24px;
  width: 24px;
`;
const NormalStyles = css`
  ${({ theme }) => `border-top: 3px solid ${theme.primary5}`};
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 3px solid transparent;
  height: 36px;
  width: 36px;
`;
const MediumStyles = css`
  ${({ theme }) => `border-top: 3.5px solid ${theme.primary5}`};
  border-right: 3.5px solid transparent;
  border-bottom: 3.5px solid transparent;
  border-left: 3.5px solid transparent;
  height: 48px;
  width: 48px;
`;
const BigStyles = css`
  ${({ theme }) => `border-top: 4px solid ${theme.primary5}`};
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  height: 64px;
  width: 64px;
`;

const SpinnerIcon = styled.div<{
  sizeStyles: FlattenInterpolation<ThemeProps<any>>;
}>`
  animation: ${rotate360} 1s linear infinite;
  border-radius: 50%;
  ${({ sizeStyles }) => sizeStyles};
  transition: all 0.2s ease-in-out;
`;

export {
  BigStyles,
  MediumStyles,
  NormalStyles,
  SmallStyles,
  SpinnerIcon,
  Wrapper,
};
