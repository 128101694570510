import { FC, HTMLAttributes, ReactNode } from "react";

import { StyledLi, StyledParagraph, StyledSpan } from "./styles";
import {
  bold10,
  bold12,
  bold14,
  bold16,
  bold18,
  bold20,
  bold24,
  medium10,
  medium12,
  medium14,
  medium16,
  medium18,
  medium20,
  medium24,
  regular10,
  regular12,
  regular14,
  regular16,
  regular18,
  regular20,
  regular24,
  regular32,
  Regular32,
  semiBold10,
  semiBold12,
  semiBold14,
  semiBold16,
  semiBold18,
  semiBold20,
  semiBold24,
  big64,
  big90,
  big96,
  big,
} from "./variants";

export type TextVariants =
  | "regular10"
  | "regular12"
  | "regular14"
  | "regular16"
  | "regular18"
  | "regular20"
  | "regular24"
  | "regular32"
  | "Regular32"
  | "medium10"
  | "medium12"
  | "medium14"
  | "medium16"
  | "medium18"
  | "medium20"
  | "medium24"
  | "semiBold10"
  | "semiBold12"
  | "semiBold14"
  | "semiBold16"
  | "semiBold18"
  | "semiBold20"
  | "semiBold24"
  | "bold10"
  | "bold12"
  | "bold14"
  | "bold16"
  | "bold18"
  | "bold20"
  | "bold24"
  | "big64"
  | "big90"
  | "big96"
  | "big";

export interface TextProps
  extends HTMLAttributes<
    HTMLParagraphElement | HTMLSpanElement | HTMLLIElement
  > {
  type?: "p" | "span" | "li";
  variant?: TextVariants;
  fz?: number;
  align?: "left" | "right" | "center" | "justify";
  decoration?: "none" | "underline" | "line-through";
  fs?: "normal" | "italic";
  transform?: "none" | "uppercase" | "lowercase" | "capitalize";
  isEllipsis?: boolean;
  isNotSelectable?: boolean;
  width?: string;
  margin?: string;
  padding?: string;
  className?: string;
  children: ReactNode;
}
export const AppText: FC<TextProps> = (props) => {
  const { type = "p", variant = "regular14", children, ...rest } = props;

  const variantsObj = {
    regular10,
    regular12,
    regular14,
    regular16,
    regular18,
    regular20,
    regular24,
    regular32,
    Regular32,
    medium10,
    medium12,
    medium14,
    medium16,
    medium18,
    medium20,
    medium24,
    semiBold10,
    semiBold12,
    semiBold14,
    semiBold16,
    semiBold18,
    semiBold20,
    semiBold24,
    bold10,
    bold12,
    bold14,
    bold16,
    bold18,
    bold20,
    bold24,
    big64,
    big90,
    big96,
    big,
  };

  switch (type) {
    case "li":
      return (
        <StyledLi {...rest} type={type} variant={variantsObj[variant]}>
          {children}
        </StyledLi>
      );
    case "span":
      return (
        <StyledSpan {...rest} type={type} variant={variantsObj[variant]}>
          {children}
        </StyledSpan>
      );
    default:
      return (
        <StyledParagraph {...rest} type={type} variant={variantsObj[variant]}>
          {children}
        </StyledParagraph>
      );
  }
};
