import React, { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  @media (max-width: 1150px) {
    margin: 15px;
  }
`;
const IconWrapper = styled.div`
  height: 90px;
`;
interface SectionProps extends HTMLAttributes<HTMLDivElement> {
  titleName?: ReactNode;
  description?: ReactNode;
  image?: ReactNode;
}

export const CategoriesBlock: FC<SectionProps> = (props) => {
  const { titleName, description, image } = props;
  return (
    <Container>
      <IconWrapper>{image}</IconWrapper>
      {titleName}
      {description}
    </Container>
  );
};
