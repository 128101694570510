import React from "react";
import "./App.css";
import styled from "styled-components";
import { AppLayout } from "./Components/Template/AppLayout";
const Container = styled.div`
  box-sizing: border-box;
`;
function App() {
  return (
    <Container>
      <AppLayout />
    </Container>
  );
}
export default App;
