import { FC } from "react";
import styled from "styled-components";

import { AppText } from "../AppText";
import { Icon } from "../Icon";

const TextBlock = styled.div<{ text?: string }>`
  display: flex;
  align-items: center;
  overflow-y: hidden;
  transition: max-height 0.25s linear;
  padding: 0 5px;
  max-height: ${(p) => (p.text ? "32px" : "0px")};
  ${(p) => !!p.text && "margin-top: 8px"};
`;

const Title = styled(AppText)<{ error?: boolean; description?: boolean }>`
  color: ${(p) =>
    p.error
      ? p.theme.error
      : p.description
      ? p.theme.grayScale2_40
      : "transparent"};
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const SuccessIcon = styled(Icon)`
  min-width: 18px;
  margin: -6px 2px -6px -2px;
`;

const ErrorIcon = styled(Icon)`
  min-width: 22.5px;
  fill: ${(p) => p.theme.error};
  margin: -6px 0 -6px -6px;
`;

export interface DescriptionComponentsProps {
  error?: string;
  description?: string;
  className?: string;
  infoStatus?: "completed" | "error";
}
export const DescriptionComponents: FC<DescriptionComponentsProps> = (
  props,
) => {
  const { error, description, className, infoStatus } = props;

  const infoStatusData = {
    completed: <SuccessIcon size={18} name={"successSmall"} />,
    error: <ErrorIcon size={22.5} name={"error"} />,
  };

  return (
    <TextBlock text={error || description} className={className}>
      {infoStatus &&
        !error &&
        infoStatusData?.[infoStatus as keyof typeof infoStatusData]}
      {error && <ErrorIcon size={22.5} name="error" />}
      <Title error={!!error} description={!!description} variant="regular12">
        {error || description}
      </Title>
    </TextBlock>
  );
};
