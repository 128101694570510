import React, { HTMLAttributes } from "react";
import styled from "styled-components";

import { Icon } from "../Icon";
import { Layout } from "../../../assets";

const Wrapper = styled.div<{ size: number }>`
  ${Layout.flexRowCenter}
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  cursor: pointer;
`;

export interface ClickableIconProps extends HTMLAttributes<HTMLDivElement> {
  name: string;
  className?: string;
  size?: number;
  wrapperSize?: number;
  disabled?: boolean;
  onClick?: () => void;
}

export const ClickableIcon: React.FC<ClickableIconProps> = (props) => {
  const {
    size = 30,
    wrapperSize = 30,
    name,
    onClick,
    disabled,
    className,
  } = props;

  return (
    <Wrapper
      onClick={() => {
        if (!disabled) onClick?.();
      }}
      size={wrapperSize}
      className={className}
    >
      <Icon name={name} size={size} />
    </Wrapper>
  );
};
