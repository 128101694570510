import { css } from "styled-components";

const regular10 = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
`;
const regular12 = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
`;
const regular14 = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;
const regular16 = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;
const regular18 = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
`;
const regular20 = css`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`;
const regular24 = css`
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
`;
const medium10 = css`
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
`;
const medium12 = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;
const medium14 = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;
const medium16 = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;
const medium18 = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
`;
const medium20 = css`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;
const medium24 = css`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`;
const semiBold10 = css`
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
`;
const semiBold12 = css`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;
const semiBold14 = css`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`;
const semiBold16 = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;
const semiBold18 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;
const semiBold20 = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;
const semiBold24 = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;
const bold10 = css`
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
`;
const bold12 = css`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;
const bold14 = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
const bold16 = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
`;
const bold18 = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
`;
const bold20 = css`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`;
const bold24 = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
`;

export {
  bold10,
  bold12,
  bold14,
  bold16,
  bold18,
  bold20,
  bold24,
  medium10,
  medium12,
  medium14,
  medium16,
  medium18,
  medium20,
  medium24,
  regular10,
  regular12,
  regular14,
  regular16,
  regular18,
  regular20,
  regular24,
  semiBold10,
  semiBold12,
  semiBold14,
  semiBold16,
  semiBold18,
  semiBold20,
  semiBold24,
};
