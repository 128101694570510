import { FC, HTMLAttributes } from "react";
import { Icons } from "../../../assets";

export interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {
  name: string | keyof typeof Icons;
  className?: string;
  width?: string | number;
  height?: string | number;
  size?: number;
}

export const Icon: FC<IconProps> = (props) => {
  const { name, className, size, width, height, onClick } = props;

  if (!Icons[name as keyof typeof Icons]) return null;

  const Svg = Icons[name as keyof typeof Icons];

  const iconWidth =
    width !== undefined ? width : size !== undefined ? size : 30;

  const iconHeight =
    height !== undefined ? height : size !== undefined ? size : 30;

  return (
    <Svg
      width={iconWidth}
      height={iconHeight}
      className={className}
      onClick={onClick}
    />
  );
};
