import { FC, HTMLAttributes, ReactNode } from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

import {
  medium10,
  medium12,
  medium14,
  medium16,
  medium18,
  medium20,
  medium24,
  regular10,
  regular12,
  regular14,
  regular16,
  regular18,
  regular20,
  regular24,
  semiBold10,
  semiBold12,
  semiBold14,
  semiBold16,
  semiBold18,
  semiBold20,
  semiBold24,
  bold10,
  bold12,
  bold14,
  bold16,
  bold18,
  bold20,
  bold24,
} from "./variants";

type Variants =
  | "regular10"
  | "regular12"
  | "regular14"
  | "regular16"
  | "regular18"
  | "regular20"
  | "regular24"
  | "medium10"
  | "medium12"
  | "medium14"
  | "medium16"
  | "medium18"
  | "medium20"
  | "medium24"
  | "semiBold10"
  | "semiBold12"
  | "semiBold14"
  | "semiBold16"
  | "semiBold18"
  | "semiBold20"
  | "semiBold24"
  | "bold10"
  | "bold12"
  | "bold14"
  | "bold16"
  | "bold18"
  | "bold20"
  | "bold24";

const StyledLink = styled.a<{
  fz?: number;
  decoration?: "none" | "underline" | "line-through";
  fs?: "normal" | "italic";
  transform?: "none" | "uppercase" | "lowercase" | "capitalize";
  margin?: string;
  padding?: string;
  variant?: FlattenSimpleInterpolation;
}>`
  ${({ variant }) => variant};
  ${({ fz }) => !!fz && `font-size: ${fz}px`};
  color: ${(p) => p.theme.link};
  font-style: ${({ fs }) => fs || "normal"};
  ${({ transform }) => !!transform && `text-transform: ${transform}`};
  text-decoration: ${({ decoration }) => decoration || "underline"};
  ${({ margin }) => !!margin && `margin: ${margin}`};
  ${({ padding }) => !!padding && `padding: ${padding}`};
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  &:hover {
    filter: contrast(150%);
  }
`;

export interface AnchorProps extends HTMLAttributes<HTMLAnchorElement> {
  variant?: Variants;
  fz?: number;
  decoration?: "none" | "underline" | "line-through";
  fs?: "normal" | "italic";
  transform?: "none" | "uppercase" | "lowercase" | "capitalize";
  margin?: string;
  padding?: string;
  className?: string;
  children: ReactNode;
  href: string;
  blank?: boolean;
}
export const Anchor: FC<AnchorProps> = (props) => {
  const {
    variant = "regular14",
    children,
    href,
    blank = true,
    ...rest
  } = props;
  const variantsObj = {
    regular10,
    regular12,
    regular14,
    regular16,
    regular18,
    regular20,
    regular24,
    medium10,
    medium12,
    medium14,
    medium16,
    medium18,
    medium20,
    medium24,
    semiBold10,
    semiBold12,
    semiBold14,
    semiBold16,
    semiBold18,
    semiBold20,
    semiBold24,
    bold10,
    bold12,
    bold14,
    bold16,
    bold18,
    bold20,
    bold24,
  };

  return (
    <StyledLink
      variant={variantsObj[variant]}
      href={href}
      target={blank ? "_blank" : "_self"}
      rel="noreferrer"
      {...rest}
    >
      {children}
    </StyledLink>
  );
};
