import { FC, HTMLAttributes, ReactNode } from "react";
import styled from "styled-components";
import { Layout } from "../../../assets";
const Wrapper = styled.div<{
  margin?: string;
  padding?: string;
  minHeight?: string;
  noGap?: boolean;
}>`
  ${Layout.flexRowCenterLeft};
  ${({ noGap }) => !noGap && "gap: 0 15px"};
  width: 100%;
  min-height: ${({ minHeight }) => minHeight || "50px"};
  background-color: ${({ theme }) => theme.bgCard};
  border-radius: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  padding: 10px;
  ${({ margin }) => !!margin && `margin: ${margin}`};
  ${({ padding }) => !!padding && `margin: ${padding}`};
`;

export interface RowItemProps extends HTMLAttributes<HTMLDivElement> {
  margin?: string;
  padding?: string;
  minHeight?: string;
  className?: string;
  noGap?: boolean;
  children: ReactNode;
}

export const RowItem: FC<RowItemProps> = (props) => {
  const { children, ...rest } = props;
  return <Wrapper {...rest}>{children}</Wrapper>;
};
