import {FC} from "react";
import styled from "styled-components";

import {Icon} from "../Icon";
import {RowItem} from "../RowItem";
import {AppText} from "../AppText";

const Wrapper = styled(RowItem)<{
  margin?: string;
  disabled?: boolean;
  error?: boolean;
}>`
  cursor: pointer;
  ${(p) =>
    p.disabled &&
    `pointer-events: none;
        & > svg {fill: ${p.theme.grayScale2_40}};
        & > p {color: ${p.theme.grayScale2_40}};`}
  ${(p) => p.error && `border solid 1px ${p.theme.error}`};
  ${(p) => p.margin && `margin: ${p.margin}`};
`;

const PlusIcon = styled(Icon)`
  fill: ${({ theme }) => theme.grayScale2_70};
`;

export interface AddButtonRowProps {
  title: string;
  onClick?: () => void;
  margin?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
}

export const AddButtonRow: FC<AddButtonRowProps> = (props) => {
  const { title, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <PlusIcon name={"plus"} />
      <AppText variant="medium16">{title}</AppText>
    </Wrapper>
  );
};
