import {ReactComponent as Logo} from"./Logo.svg"
import {ReactComponent as microphone} from"./microphone.svg"
import {ReactComponent as humans} from"./humans.svg"
import {ReactComponent as headset} from"./headset.svg"
import {ReactComponent as arrows} from"./arrows.svg"
import {ReactComponent as twoHands} from"./twoHands.svg"
import {ReactComponent as landingPeople} from"./landingPeople.svg"
import {ReactComponent as burgerCheckList} from"./burgerCheckList.svg"


export const Icons=
    {
        Logo:Logo,
        humans:humans,
        microphone:microphone,
        headset:headset,
        arrows:arrows,
        twoHands:twoHands,
            landingPeople:landingPeople,
        burgerCheckList:burgerCheckList,
    }
