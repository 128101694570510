import React, { FC } from "react";
import styled from "styled-components";
import { AppText } from "../../../Components/atoms";
import { Wrapper } from "../../../Components/atoms/Wrapper";
import { Images } from "../../Images";
const Container = styled.div`
  width: 100%;
  height: 587px;
  display: flex;
  justify-content: space-between;
  background: #0f1717;
  @media (max-width: 1150px) {
    height: 100%;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
  }
`;
const WrapperSection2 = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  height: 587px;
  @media (max-width: 1150px) {
    height: 100%;
    padding: 0 20px 0 20px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
  }
`;
const LeftBlockWrapper = styled.div`
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 1150px) {
    margin-left: 0px;
    padding-left: 0;
    align-self: center;
    margin-bottom: 40px;
    width: min(100%, 600px);
  }
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 495px;
  @media (max-width: 1150px) {
    align-self: center;
    width: min(100%, 600px);
  }
`;
const Image = styled.img`
  height: 92%;
  width: auto;
  align-self: flex-end;
  object-fit: contain;
  background-position: center;
  margin-right: 50px;
  @media (max-width: 1300px) {
    height: auto;
    width: 50%;
  }
  @media (max-width: 1150px) {
    align-self: center;
    width: min(100%, 600px);
    margin-right: 0;
  }
`;
const Title = styled(AppText)`
  margin-top: 97px;
  @media (max-width: 1150px) {
    margin-top: 30px;
  }
`;
export const Section2: FC = () => {
  const data = [
    {
      title: "The Vision in Las Vegas",
      description:
        "A group of innovative minds saw the opportunity to revolutionize the pawn shop industry in Las Vegas. They aimed to bridge the gap between pawnbrokers and buyers, creating an online platform to connect and sell treasured items.",
    },
    {
      title: "Technological Innovation",
      description:
        "PawnsBoard was not just another marketplace. It was a vision fueled by technology and deep industry understanding. The team developed a user-friendly platform, integrating top-notch security measures for safe and transparent transactions.",
    },
    {
      title: "Nationwide Success",
      description:
        " PawnsBoard made a grand entrance onto the Las Vegas stage, capturing the attention of pawnbrokers and buyers. The marketplace thrived as pawnbrokers expanded their customer base, connecting with buyers nationwide. The success in Las Vegas paved the way for PawnsBoard to become the go-to marketplace for pawn shops across the country.",
    },
  ];

  return (
    <Container>
      <WrapperSection2 id={"section2"} widthContainer={1760}>
        <LeftBlockWrapper>
          <Title color={"white"} variant={"regular32"}>
            PawnsBoard Story
          </Title>
          {data.map((item, index) => (
            <TextWrapper key={index}>
              <AppText color={"#FFB800"} variant={"regular20"}>
                {item.title}
              </AppText>
              <AppText color={"#FFFFFF"} variant={"regular14"}>
                {item.description}
              </AppText>
            </TextWrapper>
          ))}
        </LeftBlockWrapper>
        <Image src={Images.monitor} />
      </WrapperSection2>
    </Container>
  );
};
