import React, {FC} from "react";
import styled from "styled-components";
import {AppText, Button, Icon} from "../../../Components/atoms";
import {Input} from "../../../Components/atoms/Input";
import {Wrapper} from "../../../Components/atoms/Wrapper";
import {useEmailForm} from "../../../hooks/useEmailForm";

const Container = styled.div`
    width: 100%;
    padding-top: 80px;
    background: #E5DDC9;
    position: relative;
    @media (max-width: 1150px) {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
    }
`
const Title = styled(AppText)`
    @media (max-width: 1150px) {
        margin: 0 auto;
        text-align: center;
        //display: inline;
        //align-self: center;
    }
`
const BlocksWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    @media (max-width: 1150px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: center;
        margin-bottom: 40px;
    }
`
const LeftBlock = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 30px;
    @media (max-width: 1150px) {
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        width: min(100%, 600px);
    }
`
const Btn = styled(Button)`
    width: 100%;
    height: 36px;
    background: #ABA082;
    color: white;
    border-radius: 10px;
`
const RightBlock = styled.div`
    width: 30%;
    margin-bottom: 20px;
    @media (max-width: 1150px) {
        z-index: 2;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        width: min(100%, 600px);
    }
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`
const CenterBlock = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 0;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translate(-55%);
`
const CompanyName = styled.div`
    width: 100%;
    height: 93px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0F1717;
`
export const Section5: FC = () => {
    const data = [{description: "From an economic standpoint, PawnsBoard brings several advantages and differentiators compared to existing marketplaces"}, {
        title: "Targeted Market Niche", description:
            "PawnsBoard focuses specifically on the pawn industry, catering to the needs of pawnbrokers and buyers dealing with a wide range of valuable items. This niche market has significant economic potential"
    }, {
        title:
            "Increased Market Efficiency"
        , description:
            "PawnsBoard connects a larger network of pawnbrokers and buyers across a wider geographical area, breaking traditional barriers. It expands market access, improves liquidity, and enhances the overall economic efficiency of the pawn industry."
    }, {
        title: "Improved Profitability for Pawnbrokers", description:
            "Pawnbrokers gain additional sales channels beyond their physical stores, increasing exposure and sales volumes. This reduces reliance on local foot traffic and expands their potential buyer pool."
    }, {
        title: "Access to Capital",
        description: "PawnsBoard provides pawnbrokers with easier access to capital by allowing them to list items for purchase or lending. This opens up opportunities for funding, supporting business expansion and financial needs."
    }, {
        title: "Job Creation and Local Economic Impact",
        description: "PawnsBoard's growth facilitates job creation within the pawn industry, as increased sales volumes and profitability lead to the hiring of additional staff. This contributes to the economic vibrancy of local communities."
    }, {description: "Overall, PawnsBoard's economic impact lies in unlocking untapped market potential, improving efficiency, enhancing profitability, facilitating access to capital, and fostering local economic development in the pawn industry."}]
    return (
        <Container id={"section4"}>
            <Wrapper>

                <Title variant={"big96"}>Become a venture</Title>
                <BlocksWrapper>
                    <LeftBlock>
                        <AppText variant={"bold20"}>PawnsBoard: Transforming the Pawn Industry with Venture
                            Support</AppText>
                        <AppText margin={"5px 0 12px 0"} variant={"regular14"}>
                            PawnsBoard aims to transform the pawn industry by connecting pawnbrokers with global buyers.
                            Venture capitalists recognized its potential and joined forces, accelerating its development
                            and creating a robust marketplace. This collaboration marks a new era of innovation and
                            financial support reshaping traditional industries for growth and success.</AppText>
                        <EmailForm/>
                    </LeftBlock>
                    <CenterBlock>
                        <Icon width={356} height={551} name={"landingPeople"}/>
                    </CenterBlock>
                    <RightBlock>
                        {data.map((item, index) => <TextWrapper key={index}><AppText margin={"10px 0 0 0"}
                                                                                     variant={"bold14"}>{item?.title}</AppText>
                            <AppText variant={"regular14"}>{item?.description}</AppText> </TextWrapper>)}
                    </RightBlock>
                </BlocksWrapper>
            </Wrapper>
            <CompanyName><AppText variant={"bold20"} color={"#FFFFFF"}>© 2023 PawnsBoard.com</AppText></CompanyName>
        </Container>
    )
}


const EmailForm = () => {

    const {onChange, submit, error} = useEmailForm()
    return <>
        <Input placeholder={'Full name'} name="name" size={30} onChange={onChange} error={error}/>
        <Input placeholder={'Email'} type="email" size={30} name="email" onChange={onChange} error={error}/>
        <Input placeholder={'Company name'} size={30} name="companyName" onChange={onChange} error={error}/>
        <Input textArea placeholder={'Write your message here'} size={100} name="message" onChange={onChange}
               error={error}/>
        <Btn size={"big"} color={'red'} onClick={submit}>Button</Btn>
    </>
}
