import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { Layout } from "../../../assets";
const Wrapper = styled.div<{
  mainStyles: FlattenInterpolation<ThemeProps<any>>;
}>`
  ${Layout.flexColCenter};
  min-width: 55px;
  border-radius: 6px;
  padding: 5px 10px;
  ${({ mainStyles }) => mainStyles};
  & > p {
    overflow: hidden;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    ${({ mainStyles }) => mainStyles};
    border: none;
    background: none;
    text-align: center;
  }
`;

const StandardBoldStyles = css`
  background-color: ${({ theme }) => theme.bgMain};
  color: ${({ theme }) => theme.grayScale2_60};
`;
const StandardOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.grayScale2_10};
  color: ${({ theme }) => theme.grayScale2_60};
`;

const WarningBoldStyles = css`
  background-color: ${({ theme }) => theme.warning};
  color: ${({ theme }) => theme.static1};
`;
const WarningOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.warning};
  color: ${({ theme }) => theme.warning};
`;

const SuccessBoldStyles = css`
  background-color: ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.static1};
`;
const SuccessOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.success};
`;

const ErrorBoldStyles = css`
  background-color: ${({ theme }) => theme.error};
  color: ${({ theme }) => theme.static1};
`;
const ErrorOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.error};
  color: ${({ theme }) => theme.error};
`;

const PrimaryBoldStyles = css`
  background-color: ${({ theme }) => theme.link};
  color: ${({ theme }) => theme.static1};
`;
const PrimaryOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.link};
  color: ${({ theme }) => theme.link};
`;

const NeutralBoldStyles = css`
  background-color: ${({ theme }) => theme.grayScale2_30};
  color: ${({ theme }) => theme.static1};
`;
const NeutralOutlineStyles = css`
  border: solid 1px ${({ theme }) => theme.grayScale2_30};
  color: ${({ theme }) => theme.grayScale2_30};
`;
export {
  ErrorBoldStyles,
  ErrorOutlineStyles,
  PrimaryBoldStyles,
  PrimaryOutlineStyles,
  StandardBoldStyles,
  StandardOutlineStyles,
  SuccessBoldStyles,
  SuccessOutlineStyles,
  WarningBoldStyles,
  WarningOutlineStyles,
  NeutralBoldStyles,
  NeutralOutlineStyles,
  Wrapper,
};
