import React, {FC, useRef} from "react";
import styled from "styled-components";
import {AppText, Button, Icon} from "../../../Components/atoms";
import {Input} from "../../../Components/atoms/Input";
import {Images} from "../../../assets/Images";
import {CategoriesBlock} from "../../../Components/molecules/CategoriesBlock";
import {Wrapper} from "../../../Components/atoms/Wrapper";
import {useEmailForm} from "../../../hooks/useEmailForm";

const Container = styled.div`
    width: 100%;
    background-color: #FFB800;
    padding-block: 50px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding-block: 30px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }
`
const BlockWrapper = styled.div`
    background-image: url(${Images.people});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 50px;
    border-radius: 20px;
    gap: 30px;
    @media (max-width: 1150px) {
        margin-top: 20px;
        padding: 30px;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
    }
    @media (max-width: 568px) {
        padding: 20px;
    }
`
const LeftBlock = styled.div`
    width: 500px;
    display: flex;
    //margin: auto 0 auto 40px;
    flex-direction: column;
    gap: 10px;
    @media (max-width: 1150px) {
        //margin-left: 0px;
        align-self: center;
        width: min(100%, 600px);
    }
`
const RightBlock = styled.div`
    width: 300px;
    display: flex;
    //margin-top: 80px;
    //margin-right: 40px;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    //padding: 15px;
    @media (max-width: 1150px) {
        //padding: 15px 0;
        width: min(100%, 600px);
        //margin-right: 0;
        align-self: center;
    }
`
const Btn = styled(Button)`
    width: 100%;
    background: #126949;
    color: white;
    border-radius: 5px;
`
const CategoriesBlockWrapper = styled.div`
    width: 100%;
    background: #FFB800;
    padding: 10px 30px;
    gap: clamp(10px, 1vw, 30px);
    border-radius: 20px;
    align-self: center;
    border: 0.5px rgba(255, 255, 255, 0.2) solid;
    z-index: 2;
    display: grid;
    margin-top: -260px;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 300px), 1fr));
    @media (max-width: 1150px) {
        padding: 15px;
    }
    @media (max-width: 568px) {
        padding: 5px;
    }
`;
const CategoriesIcon = styled(Icon)`
    width: clamp(50px, 5vw, 111px);
    aspect-ratio: 1 / 1;
`
export const Section1: FC = () => {

    const data = [{
        id: 0,
        width: "62px",
        height: "60px",
        titleName: "Connect with a National Audience",
        description: "Expand your reach beyond your local customer base. PawnsBoard allows you to showcase your items to buyers from all over the United States, increasing your chances of making a sale.",
        icon: 'humans'
    }, {
        id: 1,
        width: "40px",
        height: "68.95px",
        titleName: "Effortless Item Listings",
        description: "Our user-friendly platform makes it easy to list your pawned items for sale. With just a few clicks, you can upload detailed descriptions, high-quality images, and accurate pricing information, ensuring maximum visibility for your inventory.",
        icon: 'microphone'
    }, {
        id: 2,
        width: "68px",
        height: "76px",
        titleName: " Transactions",
        description: "Trust and security are paramount in the pawn industry. We've implemented robust measures to protect both buyers and sellers throughout the transaction process. Rest assured that your transactions will be conducted in a safe and transparent environment.",
        icon: 'arrows'
    }, {
        id: 3,
        width: "111.11px",
        height: "58.7px",
        titleName: "Real-Time Communication",
        description: "Stay connected with potential buyers through our integrated messaging system. Answer questions, negotiate prices, and finalize deals without the need for additional communication channels.",
        icon: 'twoHands'
    }, {
        id: 4,
        width: "73.75px",
        height: "73.75px",
        titleName: "Customer Support",
        description: "Our dedicated customer support team is here to assist you every step of the way. Whether you have questions about listing items or need technical assistance, we're just a click away.",
        icon: 'headset'
    },

    ]


    return (
        <Container>
            <Wrapper widthContainer={1760}>
                <CategoriesBlockWrapper>
                    {data.map((item, index) => <CategoriesBlock key={index} image={<CategoriesIcon name={item.icon}
                                                                                                   height={item?.height}
                                                                                                   width={item?.width}/>}
                                                                titleName={<AppText variant={"regular16"}
                                                                                    color={"#0F1717"}>{item.titleName}</AppText>}
                                                                description={<AppText margin={"10px 0 0 0 "}
                                                                                      color={"#0F1717"}
                                                                                      variant={"regular14"}>{item.description}</AppText>}/>)}
                </CategoriesBlockWrapper>
                <BlockWrapper>
                    <LeftBlock>
                        <AppText color={"#FFB800"} variant={"regular32"}>Join Our Exclusive Marketplace Today!</AppText>
                        <AppText color={"white"}>As we prepare to launch in Las Vegas, USA, we invite you to be part of
                            our exclusive marketplace. By signing up early, you'll gain early access to our platform,
                            allowing you to establish your presence and start reaching a wider audience before our
                            nationwide expansion</AppText>
                        <AppText color={"white"}>
                            To get started, simply visit our website and provide your contact information. We'll keep
                            you updated with the latest news, launch dates, and exciting developments as we prepare to
                            revolutionize the pawn industry together.
                        </AppText>
                        <AppText color={"white"}>
                            Don't miss out on this incredible opportunity to enhance your business and maximize your
                            sales. PawnsBoard is the future of pawn shop marketplaces, and we're thrilled to have you
                            join us on this exciting journey. Together, we'll transform the way you do business, one
                            item at a time.
                        </AppText>
                    </LeftBlock>
                    <RightBlock>
                        <EmailForm/>
                    </RightBlock>
                </BlockWrapper>
            </Wrapper>
        </Container>
    )
}


const EmailForm = () => {
 const { onChange , submit,error} = useEmailForm()
    return <>
        <Input placeholder={'Full name'} name="name" size={30} onChange={onChange} error={error}/>
        <Input placeholder={'Email'} type="email" size={30} name="email"  onChange={onChange} error={error} />
        <Input placeholder={'Company name'} size={30} name="companyName"  onChange={onChange}  error={error}/>
        <Input textArea placeholder={'Write your message here'} size={100} name="message"  onChange={onChange} error={error}/>
        <Btn size={"big"} color={'red'} onClick={submit}>Button</Btn>
    </>
}
