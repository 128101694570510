import React, {FC} from "react";
import styled from "styled-components";
import {AppText} from "../../../Components/atoms";
import { Wrapper } from "../../../Components/atoms/Wrapper";
const Container=styled.div`
  width: 100%;
  background: #194C49;
  overflow: hidden;
`

const ContentWrapper = styled(Wrapper)`
  @media(max-width: 1150px) {
    padding-inline: 0;
  }
`

const TopBlock=styled.div`
  display: flex;
  position: relative;
  padding-inline: 20px;
  flex-wrap: wrap;
  @media (max-width: 1150px) {
    margin-top: 0;
    //flex-direction: column;
    padding-inline: 0;
  }
  &:after {
    content: '';
    position: absolute;
    height:382px;
    width: 200vw;
    left: -100vw;
   border:1px solid #F1F1F1;
   background: rgb(251 184 0);
   @media (max-width: 1150px) {
     display: none;
   }
  }
`
const Block=styled.div<{size:number,BgColor:string}>`
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap:8px;
  padding: 10px 15px 10px 15px;
  border:1px solid #F1F1F1;
  background: ${({ BgColor }) => `${BgColor}`};
  height:${({ size }) => `${size}px`};
  @media (max-width: 1150px) {
    width: 33.33333%;
    flex: auto;
    padding: 10px 30px 20px 30px;
    height:auto;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    & p {
      width: min(600px, 100%);
      text-align: center;
    }
  }  
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 15px 20px 15px;
  }
`
const BottomBlock=styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #194C49;
  padding: 40px 15px 60px;
`
// const EmptyBlock1=styled.div`
// width:calc(71 * 5px);
//   height:382px;
//   border:1px solid #F1F1F1;
//   background: rgb(251 184 0);
//   @media (max-width: 1150px) {
//     display: none;
//   }
// `
// const EmptyBlock2=styled.div`
//    width:calc(71 * 5px);
//   height:382px;
//   border:1px solid #F1F1F1;
//   background: rgb(253 226 153);
//   @media (max-width: 1150px) {
//     display: none;
//   }
// `
const TextWrapper=styled.div`
  width: min(100%, 850px);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  align-self: center;
  gap: 30px 0;
  @media (max-width: 1150px) {
    width: min(100%, 600px);
  }
  @media (max-width: 768px) {
    gap: 15px 0;
  }
`
export const Section4:FC=()=>{
const data=[{number:1,title:"Vibrant Pawn Shop Culture",description:"Las Vegas boasts a thriving pawn shop culture that has become an integral part of the city's fabric. With numerous reputable pawn shops, each with its own unique inventory, the local market is ripe for our innovative platform. By launching in Las Vegas, we tap into the existing network of pawn shops and create a vibrant hub for buyers and sellers.",size:
        449,color:"rgb(251 191 25)"},{number:2,title:"Diverse Range of Items",description:
        "As a city renowned for its entertainment and tourism, Las Vegas attracts a diverse mix of visitors from around the world. This translates into an incredible variety of pawned items available for sale. From high-end electronics and luxury watches to rare collectibles and one-of-a-kind memorabilia, Las Vegas pawn shops offer an unparalleled selection that will captivate buyers and propel the success of PawnsBoard.",size:507,color:"rgb(252 198 52)"},{number:3,title:"Global Audience",description:
        "Las Vegas attracts millions of visitors each year, making it a truly global destination. By launching in this iconic city, we position PawnsBoard to reach a diverse international audience. Our platform will connect pawn shops with buyers not just from Las Vegas but also from all corners of the globe, creating unprecedented opportunities for expanding their customer base and driving sales.",size:568,color:"rgb(252 205 77)"},{number:4,title:"Technological Innovation Hub",description:
        "Las Vegas has rapidly emerged as a hub for technological innovation and entrepreneurship. By choosing Las Vegas as our launchpad, we tap into the dynamic tech ecosystem, drawing inspiration from the city's entrepreneurial spirit and leveraging the cutting-edge advancements that are reshaping industries. This positioning enables us to integrate the latest technologies into PawnsBoard, creating a seamless and intuitive user experience for pawn shops and buyers alike.",size:507,color:"rgb(252 212 102)"},{number:5,title:"Setting the Stage for Expansion",description:
        "Las Vegas serves as the catalyst for our nationwide expansion. By establishing a strong presence in this vibrant market, we lay the foundation for scaling PawnsBoard across the United States. Our success in Las Vegas will not only validate the effectiveness of our platform but also attract the attention and interest of pawn shops nationwide, igniting a movement that will revolutionize the pawn industry.",size:449,color:"rgb(251 217 127)"}]
    return(
        <Container>
            <ContentWrapper widthContainer={1760}>
            <TopBlock>
                {/*<EmptyBlock1/>*/}
                {data.map((item, index)=><Block key={index} size={item.size} BgColor={item.color}><AppText color={
                    "#0F1717"} variant={"big"}>{item.number}</AppText><AppText color={"#0F1717"} variant={"regular16"}>{item.title}</AppText><AppText variant={"regular14"} color={"#0F1717"}>{item.description}</AppText></Block>)}
                {/*<EmptyBlock2/>*/}
            </TopBlock>
            <BottomBlock>
                <TextWrapper>
                <AppText variant={"big90"} color={"#FFFFFF"}>
                    Transforming Pawn, Empowering Connections:
                </AppText>
                <AppText variant={"big64"} color={"#FFFFFF"}>
                    PawnConnect and Venture Partners Redefine the Industry
                </AppText>
                </TextWrapper>
            </BottomBlock>
            </ContentWrapper>
        </Container>
    )
}
