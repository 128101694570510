import { css } from 'styled-components';

const fill = css`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
const fillFixed = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const absoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const flexRowCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const flexRowCenterBetween = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const flexRowCenterAround = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;
const flexRowCenterLeft = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const flexRowCenterRight = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const flexRowStretchBetween = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
`;
const flexRowStretchAround = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
`;

const flexRowTopCenter = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;
const flexRowTopBetween = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const flexRowTopAround = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
`;
const flexRowTopLeft = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const flexRowTopRight = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
`;
const flexRowBottomCenter = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const flexRowBottomBetween = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
const flexRowBottomAround = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
`;
const flexRowBottomLeft = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
`;
const flexRowBottomRight = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

const flexColCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const flexColCenterBetween = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const flexColCenterAround = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const flexColCenterTop = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const flexColCenterBottom = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const flexColLeftCenter = css`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
`;
const flexColLeftBetween = css`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
`;
const flexColLeftAround = css`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-around;
`;
const flexColLeftTop = css`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-start;
`;
const flexColLeftBottom = css`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: flex-end;
`;

const flexColRightCenter = css`
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: center;
`;
const flexColRightBetween = css`
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: space-between;
`;
const flexColRightAround = css`
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: space-around;
`;
const flexColRightTop = css`
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: flex-start;
`;
const flexColRightBottom = css`
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: flex-end;
`;
const flexColStretchTop = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;
const flexColStretchBottom = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
`;

export const Layout = {
    fill,
    fillFixed,
    absoluteCenter,
    flexColCenter,
    flexRowCenter,
    flexRowCenterBetween,
    flexRowCenterAround,
    flexRowCenterLeft,
    flexRowCenterRight,
    flexRowStretchBetween,
    flexRowStretchAround,
    flexRowTopCenter,
    flexRowTopBetween,
    flexRowTopAround,
    flexRowTopLeft,
    flexRowTopRight,
    flexColCenterBetween,
    flexRowBottomCenter,
    flexColCenterAround,
    flexColCenterTop,
    flexRowBottomBetween,
    flexRowBottomAround,
    flexRowBottomLeft,
    flexRowBottomRight,
    flexColCenterBottom,
    flexColLeftCenter,
    flexColLeftBetween,
    flexColLeftAround,
    flexColLeftTop,
    flexColLeftBottom,
    flexColRightCenter,
    flexColRightAround,
    flexColRightTop,
    flexColRightBetween,
    flexColRightBottom,
    flexColStretchTop,
    flexColStretchBottom,
};
