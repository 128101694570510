import React, {useRef, useState} from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)
export const useEmailForm = () => {

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const [error, setError] = useState({})

    const from = useRef({
        name: '',
        email: '',
        companyName: '',
        message: ''
    })



    const submit = () => {
        const error :{
            name?: string,
            email?: string,
            companyName?: string,
            message?: string

        } = {}
        if (!from.current.name) {
            error.name = 'Name is required'

        }
        if (!from.current.email) {
            error.email = 'Email is required'
        }

        if (!from.current.message) {
            error.message = 'Message is required'
        }
        if (!emailRegex.test(from.current.email)) {
            error.email = 'Invalid email'
        }

        if (Object.keys(error).length) {
            setError(error)
            return
        }else {

            fetch(process.env.REACT_APP_EMAIL_URL as string , {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(from.current)
            }).then(res => {
                Swal.fire({
                    title: "Good job!",
                    text: "Your message has been sent successfully!",
                    icon: "success"
                });
            }).catch(err => {
                Swal.fire({
                    title: "Oops!",
                    text: "Something went wrong!",
                    icon: "error"
                });

            }).finally(() => {
               setError({})
            })
        }

    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        from.current = {
            ...from.current,
            [e.target.name]: e.target.value
        }
    }


    return {
        from,
        submit,
        onChange,
        error
    }
}
